/// <reference types="webpack/module" />
import { sysId } from './Common';
import SubSystem from './SubSystem';
const revision = import.meta.webpackHot && import.meta.webpackHot.data
    ? (import.meta.webpackHot.data['revision'] || 0) + 1
    : 0;
const previousVersion = import.meta.webpackHot && import.meta.webpackHot.data
    ? import.meta.webpackHot.data['self']
    : undefined;
export const data = previousVersion
    ? { ...previousVersion.data }
    : {
        styles: new Map(),
        styleNodes: new Map(),
        styleStatus: new Map(),
        styleClones: new Map(),
    };
export function attach(name, listener) {
    let node = data.styleNodes.get(name);
    if (!node) {
        node = load(name);
    }
    node.addEventListener('change', listener);
}
export function detach(name, listener) {
    let node = data.styleNodes.get(name);
    if (!node) {
        node = load(name);
    }
    node.removeEventListener('change', listener);
}
/**
 * Get a stylesheet, loading it if necessary.
 *
 * Returns immediately with the style element associated with *name*.
 *
 * Attach a *change* event listener to the returned node to be notified when the
 * stylesheet is loaded or updated.
 *
 *
 * @param name name/path/url of the stylesheet
 * @returns The associated STYLE element
 */
export function get(name, defaultStyles) {
    let node = data.styleNodes.get(name);
    if (!node) {
        node = load(name, false, defaultStyles);
    }
    return node.cloneNode(true);
}
export function getStyleFor(elt, name, defaultStyles) {
    let clones = data.styleClones.get(name);
    if (!clones) {
        clones = new Map();
        data.styleClones.set(name, clones);
    }
    let clone = clones.get(elt);
    if (!clone) {
        clone = get(name, defaultStyles);
        clones.set(elt, clone);
    }
    if (_self._debug)
        console.log('Styles: getting style %s for %o: %o', name, elt, clone);
    return clone;
}
export function disposeStyle(elt, name) {
    const clones = data.styleClones.get(name);
    if (clones) {
        if (_self._debug)
            console.log('Styles: disposing of style %s for %o: %o', name, elt, clones.get(elt));
        clones.delete(elt);
        if (clones.size === 0) {
            data.styleClones.delete(name);
        }
    }
    return null;
}
export function refreshClones(name, node) {
    const clones = data.styleClones.get(name);
    if (clones) {
        clones.forEach((clone, elt) => {
            if (_self._debug)
                console.log('Styles: updating style %s for %o, style element %o', name, elt, clone);
            clone.textContent = node.textContent;
        });
    }
}
/**
 * (Re)load a stylesheet.
 *
 * Returns immediately with the style element associated with *name*.
 * The stylesheet is loaded in the background with *fetch()*, will
 * trigger a *change* event on the style element when loading is complete.
 * Will not trigger a new *fetch()* if one is already in progress.
 *
 * The style element should be cloned before inserting it into the DOM; make a fresh clone on each change.
 *
 * @param name name/path/url of the stylesheet
 * @returns The associated STYLE element
 */
export function load(name, bypassCache = false, defaultStyles) {
    let node = data.styleNodes.get(name);
    if (!node) {
        node = document.createElement('style');
        node.textContent = defaultStyles ?? '* { display: none}';
        data.styleNodes.set(name, node);
    }
    if (data.styleStatus.get(name) === 'pending') {
        return node;
    }
    data.styleStatus.set(name, 'pending');
    const headers = new Headers();
    headers.append('accept', 'text/css');
    if (bypassCache) {
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
    }
    if (_self._debug)
        console.log('Styles: fetching ', _self.pathPrefix + name, headers);
    fetch(_self.pathPrefix + name, {
        method: 'GET',
        headers,
    })
        .then(async (resp) => {
        if (resp.ok) {
            const txt = await resp.text();
            data.styles.set(name, txt);
            data.styleStatus.set(name, 'loaded');
            node.textContent = txt;
            refreshClones(name, node);
            node.dispatchEvent(new Event('change'));
        }
        else {
            console.warn(`Styles: Fetching '${name}': `, resp.status, resp.statusText);
            data.styleStatus.set(name, 'error');
        }
    })
        .catch((reason) => {
        data.styleStatus.set(name, 'error');
        console.error(`Styles: Fetching '${name}': `, name, reason);
    });
    return node;
}
/**
 * (Re)load a stylesheet.
 *
 * Returns immediately with the style element associated with *name*.
 * The stylesheet is loaded in the background with *fetch()*, will
 * trigger a *change* event on the style element when loading is complete.
 * Will not trigger a new *fetch()* if one is already in progress.
 *
 * @param name name/path/url of the stylesheet
 * @returns The associated STYLE element
 */
export function update(name) {
    if (_self._debug)
        console.log('updating stylesheet', name);
    load(name, true);
}
const _self = {
    _id: sysId(import.meta.url),
    _revision: revision,
    _debug: true,
    pathPrefix: '',
    get,
    getStyleFor,
    disposeStyle,
    update,
    load,
    attach,
    detach,
    data,
    version: 7,
};
export const Styles = _self;
export default Styles;
SubSystem.declare(_self).register();
console.warn('Styles *loaded*!!!!');
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    import.meta.webpackHot.addDisposeHandler((d) => {
        d.styles = data.styles;
        d.styleNodes = data.styleNodes;
        d.styleStatus = data.styleStatus;
    });
}

import Systems from './SubSystem';
import { html, render } from 'uhtml';
import { tagName, assert, uniqueId, sysId, interpolate } from './Common';
import { BorbBaseElement, BorbElement } from './BaseElement';
import { isEqual } from 'lodash-es';
import { DragNDrop } from './DragNDrop';
import Styles from './Styles';
//import 'css/frames.css';
const styleRef = 'css/frames.css';
const revision = import.meta.webpackHot && import.meta.webpackHot.data
    ? import.meta.webpackHot.data['revision'] + 1
    : 0;
export class BorbPanel extends HTMLElement {
}
BorbPanel.tag = tagName('panel', revision);
export class BorbTab extends BorbElement {
    get targetElement() {
        if (!this._target)
            this.connectedCallback();
        return this._target;
    }
    get target() {
        return this.getAttribute('target');
    }
    set target(tgt) {
        this.setAttribute('target', tgt);
        this._target = undefined;
    }
    connectedCallback() {
        this._target = document.getElementById(this.target);
    }
    disconnectedCallback() {
        this._target = undefined;
    }
    adoptedCallback() {
        this.connectedCallback();
    }
}
BorbTab.tag = tagName('tab', revision);
const dragImage = new Image(1, 1);
dragImage.src =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
dragImage.style.userSelect = 'none';
dragImage.style.opacity = '0.1';
dragImage.style.background = 'none';
//const dragImage = (html.node`<img width="0" height="0" style="background:green!important;opacity:0%" id="transparent-pixel" src="">`;
const nameAttrs = new Map([
    [
        'tab-title',
        [
            'tab-title',
            'data-tab-title',
            'frame-title',
            'data-title',
            'data-frame-title',
        ],
    ],
    ['title-left', ['title-left']],
    ['title-right', ['title-right', 'status']],
    ['title-mid', ['title-mid']],
    ['icon', ['icon', 'data-icon']],
    ['tab-class', ['tab-class', 'data-tab-class']],
]);
class TabEntry extends HTMLButtonElement {
    constructor() {
        super();
        this.nameAttrList = [...nameAttrs.values()].flat();
        this.titleElts = {
            left: html.node `<span class="title-left></span>"`,
            mid: html.node `<span class="title-mid"></span>`,
            right: html.node `<span class="title-right"></span>`,
        };
    }
    init(elt, frame) {
        if (!elt.id)
            elt.id = uniqueId();
        this.element = elt;
        this.frame = frame;
        this.draggable = true;
        this.setAttribute('role', 'tab');
        this.setAttribute('aria-controls', this.panel.id ?? '');
        this.dataset.drop = 'true';
        this.type = 'button';
        this.observer = new MutationObserver(() => {
            this._getTitleAttrs();
            this.update();
        });
        this.observer.observe(this.element, {
            attributeFilter: this.nameAttrList,
        });
        this.element.slot = this.frame.classList.contains('no-tabs')
            ? ''
            : this.element.id;
        if (!this.panel.hasAttribute('role')) {
            this.panel.setAttribute('role', 'tabpanel');
        }
        this.addEventListener('click', this.select); // safe since 'click' is dispatched on 'this'
        DragNDrop.attachDraggable(this);
        this._getTitleAttrs();
        this.update();
        return this;
    }
    canDropTo(dest) {
        if (this.frame === dest)
            // can always move within frame
            return true;
        if (dest.classList.contains('no-tabs') || // dest doesn't accept tabs
            this.element !== this.panel)
            // tab can't leave frame
            return false;
        return true;
    }
    get panel() {
        if (this.element.targetElement) {
            return this.element.targetElement;
        }
        else {
            return this.element;
        }
    }
    dispose() {
        DragNDrop.detachDraggable(this);
        this.removeEventListener('click', this.select);
        this.remove();
        this.observer.disconnect();
    }
    nextTabEntryElement() {
        const next = this.nextElementSibling;
        if (next instanceof TabEntry) {
            return next.element;
        }
        else {
            return undefined;
        }
    }
    get hidden() {
        this._hidden = this.element.hidden || !this.titleAttrs['tab-title'];
        return this._hidden;
    }
    _getTitleAttrs() {
        this.titleAttrs = {};
        nameAttrs.forEach((attrs, key) => {
            for (const attr of attrs) {
                if (this.element.hasAttribute(attr)) {
                    this.titleAttrs[key] = this.element.getAttribute(attr);
                    break;
                }
            }
        });
        this.tabName = this.titleAttrs['tab-title'] ?? this.id;
        this.titleElts.left.innerText = this.titleAttrs['title-left'] ?? '';
        this.titleElts.mid.innerText = this.titleAttrs['title-mid'] ?? '';
        this.titleElts.right.innerText = this.titleAttrs['title-right'] ?? '';
        if (this.titleAttrs['tab-class'] !== undefined) {
            this.className = this.titleAttrs['tab-class'];
        }
    }
    select() {
        console.warn('select', this);
        const old = this.frame.selected;
        this.frame.selected = this;
        if (old !== this)
            this.frame.tabTransition();
        this.frame.queueUpdate();
        queueMicrotask(() => {
            // this.frame._focusin(undefined);
            this.panel.focus();
        });
    }
    selected(isSelected) {
        this.setAttribute('aria-selected', String(isSelected));
        if (isSelected) {
            this.element.setAttribute('aria-current', 'true');
            this.element.removeAttribute('aria-hidden');
        }
        else {
            this.element.setAttribute('aria-hidden', 'true');
            this.element.removeAttribute('aria-current');
        }
    }
    get slotId() {
        return this.element.slot;
    }
    update() {
        const hidden = this._hidden;
        render(this, html `${this.titleAttrs['icon']
            ? html `<span class="icon">${this.titleAttrs['icon']}</span>`
            : ''}<span>${this.titleAttrs['tab-title']}</span>`);
        if (hidden != this.hidden)
            this.frame.queueUpdate(true);
    }
    isLeftOf(other) {
        return (this.parentElement === other.parentElement &&
            this.offsetLeft < other.offsetLeft);
    }
    get tag() {
        return TabEntry.tag;
    }
}
TabEntry.tag = tagName('tab-button-internal', revision);
export class BorbFrame extends BorbBaseElement {
    constructor() {
        super(['css/common.css', styleRef]);
        this._tabs = new Map();
        // this._style = Styles.get(styleRef);
        this._observer = new MutationObserver((muts) => this.queueUpdate(true));
        this._nav = html.node `<nav class="tabs" role="tablist"></nav>`;
        const maxIcon = '🗖'; // '🗗'
        const minIcon = '🗕';
        this._header = html.node `<header>${this._nav}<h1 draggable="true"></h1>
            <nav class="window-tools"><button class="min-button">${minIcon}</button><button class="max-button">${maxIcon}</button></nav></header>`;
        this._overlay = document.createElement('div');
        this._overlay.classList.add('overlay');
        this._overlay.style.position = 'absolute';
        this._overlay.style.opacity = '0%';
        this._overlay.style.left = '.25rem';
        this._overlay.style.top = '1.25rem';
        this._overlay.style.zIndex = '99';
        this._overlay.style.transform = 'scale(-8,8) translate(-50%,50%)';
        this._overlay.style.textShadow = '#fff 0px 0px 1px, #fff 0px 0px 5px';
        this._overlay.style.pointerEvents = 'none';
        this._header.dataset.drop = 'true';
        this._header.addEventListener('borbdragenter', (ev) => {
            if (ev.dragSource instanceof TabEntry &&
                ev.dragSource.canDropTo(this)) {
                if (BorbFrame._debug)
                    console.log('FRAME enter', this.frameName, ev.target, ev);
                ev.allowDrop('move');
                if (ev.target === ev.dragSource) {
                    // do nothing
                }
                else if (ev.target instanceof TabEntry) {
                    ev.target.insertAdjacentElement(ev.dragSource.isLeftOf(ev.target)
                        ? 'afterend'
                        : 'beforebegin', ev.dragSource);
                }
                else {
                    this._nav.appendChild(ev.dragSource);
                }
            }
        });
        this._header.addEventListener('borbdragleave', (ev) => {
            if (ev.dragSource instanceof TabEntry && !ev.newTarget) {
                if (BorbFrame._debug)
                    console.log('FRAME leave', this.frameName, ev.target, ev);
                ev.dragState.cancelDropAttempt();
            }
        });
        this._header.addEventListener('borbdrop', (ev) => {
            if (ev.dragSource instanceof TabEntry) {
                if (BorbFrame._debug)
                    console.log('FRAME drop', this.frameName, ev.target, ev.dragSource, ev, this);
                const nextElement = ev.dragSource.nextTabEntryElement();
                if (nextElement) {
                    assert(nextElement !== ev.dragSource.element, 'nextElement !== ev.dragSource.element');
                    nextElement.insertAdjacentElement('beforebegin', ev.dragSource.element);
                    ev.acceptDrop('move');
                }
                else {
                    this.appendChild(ev.dragSource.element);
                    ev.acceptDrop('move');
                }
                if (ev.dropped) {
                    ev.dragSource.frame.queueUpdate(true);
                    ev.dragSource.frame._structureChanged = true;
                    ev.dragSource.select();
                    if (this != ev.dragSource.frame)
                        this._tabs.set(ev.dragSource.element, ev.dragSource);
                    ev.dragSource.frame = this;
                    this.updateChildren();
                    ev.dragSource.select();
                }
            }
        });
    }
    get debug() {
        return BorbFrame._debug;
    }
    set debug(d) {
        BorbFrame._debug = d;
    }
    get frameTitle() {
        const title = this.hasAttribute('frame-title')
            ? this.getAttribute('frame-title')
            : '${tab-title}';
        const tabAttrs = this.selected ? this.selected.titleAttrs : {};
        return interpolate(title, tabAttrs);
    }
    get frameName() {
        const title = interpolate(this.hasAttribute('frame-title')
            ? this.getAttribute('frame-title')
            : this.id, {});
        return `${title}${this.selected ? ':' + this.selected.tabName : ''}`;
    }
    prevTab() {
        this.id;
    }
    /** Return the position of the given element in the tab list (-1 if not present) */
    getTabOrder(elt) {
        return [...this._tabs.keys()].indexOf(elt);
    }
    update(childListChanged = false) {
        if (!this.isConnected)
            return;
        if (childListChanged)
            this.updateChildren();
        this._tabs.forEach((tab) => tab.selected(tab === this.selected));
        //if(BorbFrame._debug) console.log("render", this, this, this.isConnected, this.shadowRoot, frameTitle, dh, minIcon, maxIcon);
        try {
            const title = this.selected?.titleAttrs['title-left'] ??
                this.frameTitle ??
                '';
            render(this._header.querySelector('h1'), html `<span class="title-left">${title}</span>${this.selected
                ?.titleElts.mid ?? ''}${this.selected?.titleElts
                .right ?? ''}`);
            render(this.shadowRoot, html `${this.styles}${this._header}
                ${this.classList.contains('no-tabs')
                ? html `<slot></slot>`
                : html `<slot name=${this.selected?.panel.slot || '<none>'}
                          ><div class="empty-slot"></div
                      ></slot>`}${this._overlay}`);
        }
        catch (ex) {
            console.error('Frame.update', this, ex);
            throw ex;
        }
    }
    newTab(elt) {
        const entry = new TabEntry().init(elt, this);
        if (BorbFrame._debug)
            console.log('Frames:', this.frameName, 'adding tab', entry);
        this._tabs.set(elt, entry);
        return entry;
    }
    delTab(elt) {
        const entry = this._tabs.get(elt);
        if (entry) {
            if (BorbFrame._debug)
                console.log('Frames:', this.frameName, 'removing tab', entry, entry.element);
            if (entry.parentElement === this._nav)
                entry.dispose();
            this._tabs.delete(elt);
        }
        else {
            if (BorbFrame._debug)
                console.log('Frames:', this.frameName, 'tab removed', entry, entry.element);
        }
    }
    select(elementOrName) {
        if (!elementOrName)
            return super.select();
        this.updateChildren();
        const elt = typeof elementOrName === 'string'
            ? document.getElementById(elementOrName)
            : elementOrName;
        const tab = this._tabs.get(elt);
        if (tab) {
            tab.select();
            if (this.classList.contains('focused'))
                tab.element.focus();
            this.tabTransition();
            return true;
        }
        else {
            console.error('BorbFrame.select: no tab found for ', elementOrName, elt, this.frameName, this);
            this.queueUpdate();
            return false;
        }
    }
    updateChildren() {
        this._structureChanged = false;
        let selected = undefined;
        const lastSelected = this.selected;
        const removedChildren = new Set(this._tabs.keys());
        const before = [...this._nav.children];
        if (BorbFrame._debug)
            console.log('updateChildren', this.frameName, 'before', before);
        this._nav.replaceChildren();
        for (const elt of this.children) {
            if (['HEADER', 'FOOTER', 'ASIDE'].indexOf(elt.tagName) >= 0)
                continue;
            if (elt instanceof HTMLElement) {
                removedChildren.delete(elt);
                const entry = this._tabs.get(elt) ?? this.newTab(elt);
                if (!entry.hidden) {
                    this._nav.appendChild(entry);
                    if (entry === this.selected)
                        selected = entry;
                }
            }
        }
        const after = [...this._nav.children];
        if (BorbFrame._debug)
            console.log('updateChildren', this.frameName, 'after', after);
        removedChildren.forEach((elt) => this.delTab(elt));
        if (selected)
            this.selected = selected;
        else
            this.selected = this._nav.children?.[0];
        if (this.selected !== lastSelected)
            this.tabTransition();
        return !isEqual(before, after);
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.isConnected) {
            if (BorbFrame._debug)
                console.log('connected', this.tagName, this);
            if (!this.shadowRoot) {
                if (BorbFrame._debug)
                    console.log('creating shadow root');
                this.attachShadow({ mode: 'open' });
            }
            this.addEventListener('focusin', this._focusin, false);
            this.addEventListener('focusout', this._focusout, false);
            if (BorbFrame._debug)
                console.log('element', this.frameName, 'added to page.', this, this.isConnected, this.shadowRoot);
            this._observer.observe(this, {
                childList: true,
                attributeFilter: ['frame-title'],
            });
            DragNDrop.attachDropZone(this._header, '[role="tab"], header');
            uniqueId('tabbedFrame', this);
            // Styles.attach(styleRef, this._styleChangedHandler);
            this.queueUpdate(true);
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._observer.disconnect();
        this._nav.replaceChildren();
        this._tabs.forEach((entry) => entry.dispose());
        this._tabs.clear();
        this.removeEventListener('focusin', this._focusin, false);
        this.removeEventListener('focusout', this._focusout, false);
        DragNDrop.detachDropZone(this._header);
        // Styles.detach(styleRef, this._styleChangedHandler);
        if (BorbFrame._debug)
            console.log('removed from page.', this.frameName, this);
    }
    adoptedCallback() {
        if (BorbFrame._debug)
            console.log('moved to new page.', this.frameName, this);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (BorbFrame._debug)
            console.log('element attributes changed.', this, this.shadowRoot, name, oldValue, newValue);
        this.update();
    }
    // styleChanged() {
    //     this._style = Styles.get(styleRef);
    //     this.update();
    //     if(BorbFrame._debug) console.log('style changed', this, styleRef, this._style);
    // }
    tabTransition() {
        const icon = this.selected?.titleAttrs['icon'];
        if (icon) {
            this._overlay.innerText = icon;
            //this._overlay.innerHTML = `<svg viewBox="-16 -16 32 32" preserveAspectRatio="xMidYMid meet"><text font-size="16px" text-anchor="middle" dominant-baseline="middle"  >${icon}</text></svg>`;
            this._overlay.animate([
                { opacity: '0%' },
                {
                    opacity: '50%',
                    offset: 0.5,
                },
                { opacity: '10%' },
            ], { duration: 1000, iterations: 1 });
        }
    }
    _focusin(ev) {
        this.classList.add('focused', 'focusin');
        const last = BorbFrame.currentFocus;
        if (BorbFrame._debug)
            console.log('focusin', this.frameName, 'this:', this, 'last:', last);
        if (last !== this) {
            BorbFrame.currentFocus = this;
            BorbFrame.lastFocus = last;
            if (last)
                last.classList.remove('focused', 'focusin');
        }
        if (ev)
            ev.stopPropagation();
    }
    _focusout(ev) {
        if (BorbFrame._debug)
            console.log('focusout', this.frameName, this, ev);
        this.classList.remove('focusin');
        if (BorbFrame._debug)
            console.log(this.classList);
    }
}
BorbFrame.tag = tagName('frame', revision);
BorbFrame._debug = false;
export class BorbPanelBuilder {
    constructor() {
        this._error = [];
    }
    frame(targetFrame) {
        const fr = typeof targetFrame === 'string'
            ? document.getElementById(targetFrame)
            : targetFrame;
        if (!fr.tagName.startsWith('BORB-FRAME')) {
            const err = `Can't find frame, or not a BorbFrame: ${targetFrame}`;
            this._error.push(err);
            console.error(err, this, fr);
        }
        else {
            this._frame = fr;
        }
        return this;
    }
    get panelElement() {
        return this._panel;
    }
    id(id) {
        this._id = id;
        return this;
    }
    select(select = true) {
        this._select = select;
        return this;
    }
    title(title) {
        this._title = title;
        return this;
    }
    //panel<U extends HTMLElement = T>(panel: string, id?: string): BorbPanelBuilder<U>;
    //    panel<U extends HTMLElement>(panel: U): BorbPanelBuilder<U>;
    panel(panel, id) {
        const builder = this;
        if (panel instanceof HTMLElement) {
            console.log('htmlelement:', panel);
            builder._panel = panel;
            if (id)
                panel.id = id;
            return builder;
        }
        if (id) {
            builder._id = id;
            builder._panel = document.getElementById(id);
            if (this._panel)
                return builder;
        }
        if (typeof panel === 'string') {
            builder._panel = document.createElement(panel);
        }
        else if (typeof panel === 'function') {
            const builder = this;
            builder._panel = new panel();
        }
        if (id)
            builder._panel.id = id;
        return builder;
    }
    get strict() {
        this._strict = true;
        return this;
    }
    done() {
        if (!this._frame)
            this._error.push(`no frame specified`);
        if (!this._title)
            this._error.push(`no title specified`);
        if (this._strict && this._error.length > 0)
            throw new Error(this._error.join('; '));
        if (!this._panel)
            this._panel = document.createElement('section');
        if (this._title)
            this._panel.setAttribute('tab-title', this._title);
        if (this._id)
            this._panel.id = this._id;
        if (this._frame) {
            this._frame.appendChild(this._panel);
            if (this._select)
                this._frame.select(this._panel);
        }
        return this._panel;
    }
}
const _self = {
    _id: sysId(import.meta.url),
    _revision: revision,
    BorbFrame,
    BorbTab,
    BorbPanel,
    styleRef,
};
export const Frames = Systems.declare(_self)
    .reloadable(true)
    .depends('dom', Styles)
    .elements(BorbFrame, BorbTab, BorbPanel)
    .start((sys) => {
    customElements.define(TabEntry.tag, TabEntry, { extends: 'button' });
    return _self;
})
    .register();
export default Frames;
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    import.meta.webpackHot.accept(styleRef, () => {
        Styles.update(styleRef);
    });
    import.meta.webpackHot.addDisposeHandler((data) => {
        console.warn('Unloading TabbedFrame');
        data['revision'] = revision;
        data['Frames'] = Frames;
    });
}

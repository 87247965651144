// Generated automatically by nearley, version unknown
// http://github.com/Hardmath123/nearley
function id(x) { return x[0]; }

import moo from "moo";
import { Apply, Integer, Name } from "./ast";

const lexer = moo.compile({
  WS:     /[ \t]+/u,
  int: /[0-9]+/u,
  word: /[\p{L}\p{N}_]+/u,
  punct: /[()\[\]:;,{}]/u,
  op: /[.*+-/%|&<>=!]+/u
});
let oldNext = lexer.next;
lexer.next = () => { 
    let t;// : moo.Token;
    for(t = oldNext.call(lexer); t?.type === 'WS'; t = oldNext.call(lexer))
        ;
    return t;
}
let Lexer = lexer;
let ParserRules = [
    {"name": "main$ebnf$1", "symbols": ["statement"], "postprocess": id},
    {"name": "main$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "main$ebnf$2", "symbols": []},
    {"name": "main$ebnf$2", "symbols": ["main$ebnf$2", "more_statement"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "main", "symbols": ["main$ebnf$1", "main$ebnf$2"], "postprocess": ([a,bs]) => [a, ...bs]},
    {"name": "statement$ebnf$1", "symbols": ["query"]},
    {"name": "statement$ebnf$1", "symbols": ["statement$ebnf$1", "query"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "statement", "symbols": [{"literal":"rows"}, "statement$ebnf$1"], "postprocess": ([op,args]) => new Apply(op, ...args)},
    {"name": "statement$ebnf$2", "symbols": ["query"]},
    {"name": "statement$ebnf$2", "symbols": ["statement$ebnf$2", "query"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "statement", "symbols": [{"literal":"columns"}, "statement$ebnf$2"], "postprocess": ([op,args]) => new Apply(op, ...args)},
    {"name": "query$subexpression$1", "symbols": [{"literal":"from"}]},
    {"name": "query$subexpression$1", "symbols": [{"literal":"where"}]},
    {"name": "query", "symbols": ["query$subexpression$1", "expr_list"], "postprocess": ([op,args]) => new Apply(op, ...args)},
    {"name": "from_clause", "symbols": [{"literal":"from"}, "expr_list"], "postprocess": id},
    {"name": "more_statement$ebnf$1", "symbols": ["statement"], "postprocess": id},
    {"name": "more_statement$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "more_statement", "symbols": [{"literal":";"}, "more_statement$ebnf$1"], "postprocess": ([a,b]) => b},
    {"name": "name", "symbols": [(lexer.has("word") ? {type: "word"} : word)], "postprocess": ([a]) => new Name(a)},
    {"name": "literal", "symbols": [(lexer.has("int") ? {type: "int"} : int)], "postprocess": ([a]) => new Integer(a)},
    {"name": "expr_list$ebnf$1", "symbols": ["expr"], "postprocess": id},
    {"name": "expr_list$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "expr_list", "symbols": ["expr_list$ebnf$1"]},
    {"name": "expr_list", "symbols": ["expr", {"literal":","}, "expr_list"], "postprocess": ([a,_,bs]) => [a, ...bs]},
    {"name": "expr", "symbols": ["lambda_expr"], "postprocess": id},
    {"name": "lambda_expr", "symbols": ["cond_expr"], "postprocess": id},
    {"name": "cond_expr", "symbols": ["assign_expr"], "postprocess": id},
    {"name": "assign_expr", "symbols": ["logic_expr"], "postprocess": id},
    {"name": "logic_expr", "symbols": ["bit_expr"], "postprocess": id},
    {"name": "bit_expr", "symbols": ["eq_expr"], "postprocess": id},
    {"name": "eq_expr", "symbols": ["cmp_expr"], "postprocess": id},
    {"name": "cmp_expr", "symbols": ["shift_expr"], "postprocess": id},
    {"name": "shift_expr", "symbols": ["add_expr"], "postprocess": id},
    {"name": "add_expr", "symbols": ["mul_expr"], "postprocess": id},
    {"name": "add_expr$subexpression$1", "symbols": [{"literal":"+"}]},
    {"name": "add_expr$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "add_expr", "symbols": ["add_expr", "add_expr$subexpression$1", "mul_expr"], "postprocess": Apply.binop},
    {"name": "mul_expr", "symbols": ["exp_expr"], "postprocess": id},
    {"name": "mul_expr$subexpression$1", "symbols": [{"literal":"*"}]},
    {"name": "mul_expr$subexpression$1", "symbols": [{"literal":"/"}]},
    {"name": "mul_expr$subexpression$1", "symbols": [{"literal":"%"}]},
    {"name": "mul_expr$subexpression$1", "symbols": [{"literal":"//"}]},
    {"name": "mul_expr", "symbols": ["mul_expr", "mul_expr$subexpression$1", "exp_expr"], "postprocess": Apply.binop},
    {"name": "exp_expr", "symbols": ["unop_expr"], "postprocess": id},
    {"name": "exp_expr", "symbols": ["unop_expr", {"literal":"**"}, "exp_expr"], "postprocess": Apply.binop},
    {"name": "unop_expr", "symbols": ["apply_expr"], "postprocess": id},
    {"name": "unop_expr$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "unop_expr$subexpression$1", "symbols": [{"literal":"+"}]},
    {"name": "unop_expr$subexpression$1", "symbols": [{"literal":"!"}]},
    {"name": "unop_expr", "symbols": ["unop_expr$subexpression$1", "unop_expr"], "postprocess": Apply.unop},
    {"name": "apply_expr", "symbols": ["atom"]},
    {"name": "apply_expr", "symbols": ["apply_expr", {"literal":"."}, "name"], "postprocess": Apply.binop},
    {"name": "apply_expr", "symbols": ["apply_expr", {"literal":"("}, "expr_list", {"literal":")"}], "postprocess": ([fun,op,args,_3]) => new Apply(op.text, fun, ...args)},
    {"name": "apply_expr", "symbols": ["apply_expr", {"literal":"["}, "expr_list", {"literal":"]"}], "postprocess": ([fun,op,args,_3]) => new Apply(op.text, fun, ...args)},
    {"name": "atom", "symbols": ["literal"], "postprocess": id},
    {"name": "atom", "symbols": ["name"], "postprocess": id},
    {"name": "atom", "symbols": [{"literal":"("}, "expr", {"literal":")"}], "postprocess": id}
];
let ParserStart = "main";
export default { Lexer, ParserRules, ParserStart };

export class Expr {
    constructor(children, type) {
        this.children = children;
        this.type = type;
    }
    toString() {
        return `${this.type}(${this.children.map((c) => c.toString()).join(', ')})`;
    }
}
export class Atom extends Expr {
    constructor(data, token, type) {
        super([], type);
        this.data = data;
        this.token = token;
    }
    toString() {
        return `${this.token}`;
    }
}
export class Integer extends Atom {
    constructor(tok) {
        super(parseInt(tok.text), tok, 'int');
    }
}
export class Name extends Atom {
    constructor(token) {
        super(token.text, token, 'name');
        this.token = token;
    }
}
export class Apply extends Expr {
    static unop([op, a]) {
        return new Apply(op.text, a);
    }
    static binop([a, op, b]) {
        return new Apply(op.text, a, b);
    }
    constructor(fun, ...args) {
        super(args, fun);
    }
    toString() {
        console.log('apply', this.type, this.children);
        if (this.type === '(')
            return `${this.children[0].toString()}(${this.children
                .slice(1)
                .map((c) => c.toString())
                .join(', ')})`;
        else if (this.type === '[')
            return `${this.children[0].toString()}[${this.children
                .slice(1)
                .map((c) => c.toString())
                .join(', ')}]`;
        else
            return super.toString();
    }
}

import { isFrame } from './Common';
import Styles from './Styles';
export class BorbElement extends HTMLElement {
    constructor() {
        super();
    }
}
export class BorbBaseElement extends BorbElement {
    constructor(stylesUrls = []) {
        super();
        this.stylesUrls = stylesUrls;
        this._doUpdate = false;
        this._structureChanged = true;
    }
    connectedCallback() {
        if (this.isConnected) {
            this.styles = this.stylesUrls.map((styleRef) => Styles.getStyleFor(this, styleRef));
            this.queueUpdate();
        }
    }
    disconnectedCallback() {
        this.styles = this.stylesUrls.map((styleRef) => Styles.disposeStyle(this, styleRef));
    }
    select() {
        if (isFrame(this.parentElement)) {
            return this.parentElement.select(this);
        }
        else {
            return false;
        }
    }
    queueUpdate(structureChanged = false) {
        this._structureChanged || (this._structureChanged = structureChanged);
        if (!this._doUpdate) {
            this._doUpdate = true;
            queueMicrotask(() => {
                const change = this._structureChanged;
                this._doUpdate = false;
                this._structureChanged = false;
                if (this.isConnected)
                    this.update(change);
            });
        }
    }
}
